import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left-icon.svg';
import { IconButton } from 'components/icon-button';
import { findMatchingRoute } from 'utils/findMatchingRoute';
import { RoutePath } from 'router/routes/routePath';
import { useValidatedRoutes } from 'router/hooks/useValidatedRoutes';
import { getStructureIdFromUrl } from 'utils/getStructureIdFromUrl';
import { useGetAncestors } from 'api/graphql/hooks/structures/useGetAncestors';
import {
    generateStructureCrumb,
    singleStructureCrumb,
    useOrganizationDetailsCrumb,
} from 'components/breadcrumbs/helpers';
import {
    organizationsStart,
    structureStart,
    structuresList,
    usersList,
    organizationsList,
    structureAdd,
    settings,
    profile,
    system3e,
} from 'components/breadcrumbs/constants/staticCrumbs';
import { customScroll } from 'styles/globalStyle';
import { RouteArgs } from 'router/types';

const SNav = styled.nav`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.padding.narrow};
    width: calc(100vw - 550px);
    overflow-x: auto;
    ${customScroll};
    flex: 4;
`;

const SArrowLeftIcon = styled(ArrowLeftIcon)`
    stroke: ${({ theme }) => theme.colors.revolver};
`;

const SWrapper = styled.ul`
    display: flex;
    align-items: center;
    padding: 0;
    list-style: none;
    margin: 0;
`;

const SBreadcrumbLi = styled.li`
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.light_gray};

    &:not(:first-child):before {
        content: '/';
        display: inline-block;
        padding: 0 5px;
    }
`;

const SBreadcrumb = styled(Link)`
    color: ${({ theme }) => theme.colors.light_gray};
    text-decoration: none;
    padding: 5px 0;
    display: inline-block;
`;

const SActiveBreadcrumb = styled.span`
    display: inline-block;
    color: ${({ theme }) => theme.colors.dark_gray};
    padding: 5px 0;
`;

const SGoBackIcon = styled(IconButton)`
    display: flex;
    align-items: center;
`;

export type BreadcrumbItem = {
    name: string;
    path: string;
};

export const Breadcrumbs = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { routes } = useValidatedRoutes();
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([{ path: '', name: '' }]);

    const id = getStructureIdFromUrl(window.location.pathname);

    const { data: structureData } = useGetAncestors({ id: id || '' });

    const route = findMatchingRoute(routes, window.location.pathname) as RouteArgs;

    const organizationCrumb = useOrganizationDetailsCrumb();

    useEffect(() => {
        if (!route) {
            navigate(RoutePath.Structures, { replace: true });
        }
    }, [route]);

    useEffect(() => {
        if (structureData) {
            switch (route?.path) {
                case RoutePath.StructuresPreview: {
                    setBreadcrumbs(generateStructureCrumb(structureData));
                    break;
                }
                case RoutePath.StructuresEdit: {
                    setBreadcrumbs([
                        ...structureStart,
                        singleStructureCrumb({
                            name: structureData?.structure.name,
                            id: structureData?.structure.id,
                        }),
                        { name: t('bread-items.edit-structure'), path: '' },
                    ]);
                    break;
                }
                case RoutePath.StructuresManage: {
                    setBreadcrumbs([
                        ...generateStructureCrumb(structureData),
                        { name: t('bread-items.manage'), path: '' },
                    ]);
                    break;
                }
                case RoutePath.StructuresFloorFinishingScheme: {
                    setBreadcrumbs([
                        ...generateStructureCrumb(structureData),
                        { name: t('bread-items.finishing-scheme'), path: '' },
                    ]);
                    break;
                }
                case RoutePath.StructuresCoordinationMode: {
                    setBreadcrumbs([
                        ...generateStructureCrumb(structureData),
                        { name: t('bread-items.coordination-mode'), path: '' },
                    ]);
                    break;
                }
                case RoutePath.StructuresDirectory: {
                    setBreadcrumbs([
                        ...generateStructureCrumb(structureData),
                        { name: t('bread-items.general-mode'), path: '' },
                    ]);
                    break;
                }
            }
        } else {
            switch (route?.path) {
                case RoutePath.Structures: {
                    setBreadcrumbs(structuresList);
                    break;
                }
                case RoutePath.StructuresAdd: {
                    setBreadcrumbs(structureAdd);
                    break;
                }
                case RoutePath.Organizations: {
                    setBreadcrumbs(organizationsList);
                    break;
                }
                case RoutePath.OrganizationDetails: {
                    setBreadcrumbs([...organizationsStart, organizationCrumb as BreadcrumbItem]);
                    break;
                }
                case RoutePath.Users: {
                    setBreadcrumbs(usersList);
                    break;
                }
                case RoutePath.Settings: {
                    setBreadcrumbs(settings);
                    break;
                }
                case RoutePath.Profile: {
                    setBreadcrumbs(profile);
                    break;
                }
                case RoutePath.System3E: {
                    setBreadcrumbs(system3e);
                    break;
                }
            }
        }
    }, [route?.path, structureData]);

    const filteredBreadcrumbs = breadcrumbs.filter((item) => item?.name);

    return (
        <SNav aria-label="Breadcrumb">
            {filteredBreadcrumbs.length > 2 && (
                <SGoBackIcon
                    icon={<SArrowLeftIcon />}
                    title={t('bread-items.go-back')}
                    onClick={() => navigate(-1)}
                />
            )}
            <SWrapper>
                {filteredBreadcrumbs.map(({ path, name }, i: number) => {
                    const isLastItem = i + 1 === filteredBreadcrumbs.length;

                    return (
                        <SBreadcrumbLi key={`breadcrumb-${path}-${i}`}>
                            {isLastItem ? (
                                <SActiveBreadcrumb aria-current="page">{t(name)}</SActiveBreadcrumb>
                            ) : (
                                <SBreadcrumb to={path}>{t(name)}</SBreadcrumb>
                            )}
                        </SBreadcrumbLi>
                    );
                })}
            </SWrapper>
        </SNav>
    );
};
