import { RootRoutePath } from 'router/routes/routePath';
import { RoutePath } from 'router/routes/routePath';

export type NavItem = {
    label: string;
    path: string;
    pathRoot: RootRoutePath;
};

export const navItems: NavItem[] = [
    {
        label: 'sidebar.list-items.structures',
        path: RoutePath.Structures,
        pathRoot: RootRoutePath.Structure,
    },
    {
        label: 'sidebar.list-items.users',
        path: RoutePath.Users,
        pathRoot: RootRoutePath.User,
    },
    {
        label: 'sidebar.list-items.organisations',
        path: RoutePath.Organizations,
        pathRoot: RootRoutePath.Organization,
    },
    {
        label: 'sidebar.list-items.system3e',
        path: RoutePath.System3E,
        pathRoot: RootRoutePath.System3E,
    },
];
