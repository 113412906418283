import i18n from '../../../i18n';
import { RoutePath } from 'router/routes/routePath';

export const usersList = [
    {
        name: i18n.t('bread-items.users'),
        path: RoutePath.Users,
    },
    {
        name: i18n.t('bread-items.users-list'),
        path: RoutePath.Users,
    },
];

export const organizationsStart = [
    {
        name: i18n.t('bread-items.organizations'),
        path: RoutePath.Organizations,
    },
];

export const structureStart = [
    {
        name: i18n.t('bread-items.structures'),
        path: RoutePath.Structures,
    },
];

export const structureAdd = [
    ...structureStart,
    {
        name: i18n.t('bread-items.add-structure'),
        path: RoutePath.StructuresAdd,
    },
];

export const structuresList = [
    ...structureStart,
    {
        name: i18n.t('bread-items.structures-list'),
        path: RoutePath.Structures,
    },
];

export const organizationsList = [
    ...organizationsStart,
    {
        name: i18n.t('bread-items.organization-list'),
        path: RoutePath.Organizations,
    },
];

export const settings = [
    {
        name: i18n.t('bread-items.settings'),
        path: RoutePath.Settings,
    },
];

export const profile = [
    {
        name: i18n.t('bread-items.profile'),
        path: RoutePath.Profile,
    },
];

export const system3e = [
    {
        name: i18n.t('bread-items.system3e'),
        path: RoutePath.System3E,
    },
];
